<template>
	<el-row :gutter="160">
		<el-col :span="24" style="text-align: left;">
			<el-button type="primary" icon="el-icon-plus" @click="onClickAdd">添加新sheet配置</el-button>
			<el-button type="success" icon="el-icon-setting" @click="onClickConfigNameMgr">管理sheet配置名</el-button>            
		</el-col>
		<el-col :span="24">
			<el-table ref="multipleTable" :data="paramList" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
			:cell-style="{padding:4+'px'}" class="serverlist">
				<el-table-column prop="id" label="id" width="60"> </el-table-column>
                <el-table-column prop="groupname" label="区服列表" show-overflow-tooltip></el-table-column>
                <el-table-column prop="servername" label="区服" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ showServerName(scope.row) }}
                    </template>
                </el-table-column>
				<el-table-column prop="name" label="配置名称" show-overflow-tooltip></el-table-column>
				<el-table-column prop="ver" label="版本" show-overflow-tooltip></el-table-column>
				<el-table-column prop="desc" label="描述" show-overflow-tooltip></el-table-column>
				<el-table-column prop="filename" label="文件名" show-overflow-tooltip></el-table-column>  
                <el-table-column prop="state" label="状态" show-overflow-tooltip></el-table-column> 
				<el-table-column prop="update_time" label="更新时间" show-overflow-tooltip></el-table-column>
				<el-table-column prop="create_time" label="添加时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEditSheet(scope.row)" type="primary" icon="el-icon-edit" size="small">修改</el-button>
                        <el-button @click.native.prevent="onClickDelSheet(scope.row)" type="danger" icon="el-icon-delete" size="small">删除</el-button>
                    </template>
                </el-table-column>
			</el-table>
		</el-col>
		<!-- ========编辑弹框BEGIN====== -->
		<el-dialog :title="formTitle" :visible.sync="dialogForm" width="40%" :close-on-click-modal="false"  @open="clearInputFile" center class="formparams">
			<el-form :model="form">
                <el-form-item label="区服列表" :label-width="formLabelWidth">
                    <el-select v-model="form.groupid" placeholder="选择区服列表" value-key="id" @change="onFormSelectGroup" :disabled="editSheet">
                        <el-option v-for="item in serverGroups" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="区服" :label-width="formLabelWidth">
                    <el-select v-model="form.serverids" placeholder="不指定则是列表里全部区服" :disabled="editSheet" multiple>
                        <el-option v-for="item in formServers" :key="item.id" :label="item.title" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="配置名称" :label-width="formLabelWidth">
                    <el-select v-model="form.name" placeholder="内部名称" value-key="id" @change="onFormSelectGroup" :disabled="editSheet">
                        <el-option v-for="item in tableConfigName" :key="item.name" :label="item.name" :value="item.name">
                          <span style="float: left">{{ item.name }}</span>
                          <span style="float: right; color: #c7c7c7; font-size: 13px">{{ '('+item.desc+')' }}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="版本" :label-width="formLabelWidth">
                    <el-input v-model="form.ver" placeholder="版本号"></el-input>
                </el-form-item>
                <el-form-item label="描述" :label-width="formLabelWidth">
                    <el-input v-model="form.desc" placeholder="描述或标记"></el-input>
                </el-form-item> 
                <el-form-item label="配置选择" :label-width="formLabelWidth">
                    <input type="file" @change="onSelectFile" ref="pathClear"/>
                </el-form-item>                 
                <el-form-item label="内容" :label-width="formLabelWidth">
                    <el-input v-model="form.content" type="textarea" :rows="4" :disabled="true"  placeholder="文件内容显示"></el-input>          
                </el-form-item>   
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" icon="el-icon-check" @click="onAddSheet">确 定</el-button>
				<el-button icon="el-icon-close" @click="dialogForm = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- ========弹框END====== -->
        <!-- ========名称管理弹框BEGIN====== -->
        <el-dialog title="名称管理" :visible.sync="dialogConfigName" :close-on-click-modal="false" width="40%" center>
            <el-button type="primary" icon="el-icon-plus" @click="onClickNewConfigName">新建名称</el-button>
            <el-table :data="tableConfigName" height="410" border style="width: 100%" :row-class-name="tableRowClassName"
                :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="id号" width="60"> </el-table-column>
                <el-table-column prop="name" label="名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="desc" label="描述" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEditConfigName(scope.row)" type="primary"
                            icon="el-icon-edit" size="small">编辑</el-button>
                        <el-button @click.native.prevent="onClickDelConfigName(scope.row)" type="danger"
                            icon="el-icon-delete" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="配置名称" :visible.sync="dialogConfigNameAdd" :close-on-click-modal="false" width="30%" center>
            <el-form :model="formConfigName">
                <el-form-item label="名称" :label-width="formLabelWidth">
                    <el-input v-model="formConfigName.name"></el-input>
                </el-form-item> 
                <el-form-item label="描述" :label-width="formLabelWidth">
                    <el-input v-model="formConfigName.desc"></el-input>
                </el-form-item> 
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onCommitAddConfigName">确 定</el-button>
                <el-button icon="el-icon-close" @click="dialogConfigNameAdd=false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========分组管理弹框END====== -->
	</el-row>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'

	export default {
		props: ['pkgtype'],
		data() {
			return {
				paramList: [],
                tableConfigName: [],
                formTitle: '',
				dialogForm: false,
                dialogConfigName: false,
                dialogConfigNameAdd: false,
                form: {
                    id: '',
                    groupid:'',
                    serverids:[],
                    name: '',
                    ver: '',
                    desc: '',
                    content: '',
                    filename: '',
                },
                formConfigName: {
                    id: '',
                    name: '',
                    desc: '',
                },
                serverGroups: [],
                formServers:[],
                configNames:[],
                editSheet: false,
                formLabelWidth: '90px',
                tableHeight: document.documentElement.clientHeight-136,
			}
		},
		created: function() {
			this.refreshSheetList();
            this.refreshGroupList();
            this.refreshConfigNameList();
		},
		computed: {
			...mapGetters(
				['gameHttp']
			),
		},
		methods: {
			// 刷新列表
			refreshSheetList() {
				this.gameHttp(2101, {}).then((response) => {
					this.paramList = response.data.list
				})
			},
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(2006, {plain: 1}).then((response) => {
                    this.serverGroups = response.data.list
                    this.serverGroups.splice(0, 0, { id: -1, name: "全部区服列表"})
                })
            },
			// 弹出新增参数编辑框
			onClickAdd() {
                this.formTitle = '添加sheet配置'
				this.dialogForm = true
                this.noneChanged = true
                for (let k in this.form) {
                    this.form[k] = ''
                }
                this.form.serverids = [];
                this.formServers = [];
                this.editSheet = false;
			},
            // 表单选择分组
            onFormSelectGroup(id) {
                this.gameHttp(2009, { // 拉取服务器列表
                    listid: id,
                }).then((response) => {
                    this.formServers = response.data.list
                })
            },
            // 选择文件内容
            onSelectFile(eInput) {
                if (0 == eInput.target.files.length) { return; }
                const reader = new FileReader();
                reader.onload = (e)=>{
                    this.form.content = e.target.result;
                }
                this.form.filename = eInput.target.files[0].name
                reader.readAsText(eInput.target.files[0]);                            
            },
            // 弹出修改编辑框
            onClickEditSheet(row) {
                this.formTitle = '修改sheet配置(需选择文件)'
				this.dialogForm = true
                this.noneChanged = true 
                this.form = JSON.parse(JSON.stringify(row))
                //this.form.filename = '';
                this.formServers = row.servers;
                this.editSheet = true;
                delete this.form.update_time; // 忽略不需要提交的字段
                delete this.form.create_time; // 忽略不需要提交的字段
                delete this.form.groupname; // 忽略不需要提交的字段
                delete this.form.servername; // 忽略不需要提交的字段
                delete this.form.state; // 忽略不需要提交的字段
                delete this.form.servers; // 忽略不需要提交的字段
            },
            // 列表里的区服名显示
            showServerName(row) {
                let str = "| ";
                for (let i=0; i<row.servers.length; i++) {
                    str += row.servers[i].title + " | ";
                }
                return str;
            },
			// 添加sheet配置
			onAddSheet() {
                console.log( this.form );
                for (var key in this.form) {
                    console.log(key, this.form[key], this.form[key].length)
                    if (0 == this.form[key].length && 'id' != key && 'serverids'!=key) {
                        this.$message.error(key + ' 参数不能为空，请检查确认！');
                        return;
                    }
                }
                this.dialogForm = false
                // 请求操作
                this.gameHttp(2102, this.form).then((response) => {
                    response.data.errcode==0?this.$message.success(response.data.errmsg):this.$message.error(response.data.errmsg)
                    this.refreshSheetList()
                })
			},
            // 弹出删除确认框
            onClickDelSheet(row) {
                this.$confirm('是否删除[ '+row.id+"-"+row.name+' ]配置?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(2103, {
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("提交成功")
                            this.refreshSheetList()
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                    })
                }).catch(() => {})
            }, 
            // 清除文件选择
            clearInputFile() {
                if (!this.$refs.pathClear) { return; }
                this.$refs.pathClear.value = '';
            },
            /////////////////////////////////////
            /////////////////////////////////////
            onClickConfigNameMgr() {
                this.dialogConfigName = true
                this.refreshConfigNameList()
            },
            // 刷新配置名列表
            refreshConfigNameList() {
                this.gameHttp(2104, {}).then((response) => {
                    this.tableConfigName = response.data.list;
                })
            },
            onClickNewConfigName() {
                this.dialogConfigNameAdd = true;
                this.formConfigName.id = '';
                this.formConfigName.name = '';
                this.formConfigName.desc = '';
            },
            onClickEditConfigName(row) {
                this.dialogConfigNameAdd = true;
                this.formConfigName.id = row.id;
                this.formConfigName.name = row.name;
                this.formConfigName.desc = row.desc;
            },
            onClickDelConfigName(row) {
                this.$confirm('是否删除[ ' + row.name + ' ]配置名?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(2106, {
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("删除成功")
                            this.refreshConfigNameList()
                        }
                    })
                }).catch(() => {})
            },
            onCommitAddConfigName() {
                 if (0 == this.formConfigName.name.length) {
                     this.$message.error('请输入配置名称！');
                     return;
                 }
                 if (0 == this.formConfigName.desc.length) {
                     this.$message.error('请输入描述备注!');
                     return;
                 }                
                 this.dialogConfigNameAdd = false
                 // 请求操作
                 this.gameHttp(2105, this.formConfigName).then((response) => {
                     this.$message.success(response.data.errmsg)
                     this.refreshConfigNameList()
                 })
            },
			// 数据表色彩间隔效果     
			tableRowClassName({
				rowIndex
			}) {
				if (0 == rowIndex % 2) {
					return 'warning-row';
				} else {
					return 'success-row';
				}
			},
		},
	}
</script>

<style>
	.serverlist.el-table .warning-row {
		background: #f5f7fa;
	}

	.serverlist.el-table .success-row {
		background: #ffffff;
	}

	.serverlist.el-table {
		margin-top: 16px;
	}

	.serverlist {
		font-size: 12px;
	} 
</style>
